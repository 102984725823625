<template>
    <div :style="{ backgroundColor: props.backgroundColorPrimary, color: props.textColorPrimary }" class="fixed bottom-5 right-5 w-12 h-12 sm:w-16 sm:h-16 rounded-full flex items-center justify-center cursor-pointer" @click="toggleChat">
      <Transition name="fade">
        <ChatBubbleOvalLeftEllipsisIcon v-show="!isChatVisible" class="w-6 h-6 sm:w-10 sm:h-10 absolute"/>
      </Transition>
      <Transition name="fade">
        <XMarkIcon v-show="isChatVisible" class="w-6 h-6 sm:w-10 sm:h-10 absolute"/>
      </Transition>
    </div>
    <ChatPopup v-show="isChatVisible" :backgroundColorPrimary="props.backgroundColorPrimary" :backgroundColorSecondary="props.backgroundColorSecondary" :textColorPrimary="props.textColorPrimary" :companyUuid="props.companyUuid"/>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import ChatPopup from './ChatPopup.vue';
import { ChatBubbleOvalLeftEllipsisIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import {defineProps} from 'vue';
import { useI18n } from "vue-i18n";

const props = defineProps({
  backgroundColorPrimary: String,
  backgroundColorSecondary: String,
  textColorPrimary: String,
  language: String,
  companyUuid: String,
});

const isChatVisible = ref(false);
const toggleChat = () => {
  isChatVisible.value = !isChatVisible.value;
};

onMounted(() => {
  window.addEventListener('minimize-chat', toggleChat);
  useI18n().locale.value = props.language; // when mounting the widget, we set the locale value to the language sent as a prop
});

onUnmounted(() => {
  window.removeEventListener('minimize-chat', toggleChat);
});
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {transition: opacity 0.25s ease;}
.fade-enter-from, .fade-leave-to {opacity: 0;}
</style>
