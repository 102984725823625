<template>
  <Transition name="slide-fade">
    <div
        class="bottom-0 sm:bottom-24 w-screen sm:w-96 h-dvh sm:h-192 sm:max-h-192 shadow-md flex flex-col absolute overflow-hidden border border-gray-300 rounded-xl right-0 sm:right-6 top-auto bg-white">
      <div v-if="!isLoggedIn" class="flex flex-col h-full">
        <div
            class="flex items-center p-2 border-b border-gray-300 justify-between sticky top-0 left-0 right-0 z-10 bg-white">
          <h2 class="text-2xl font-bold">{{ $t('register') }}</h2>
          <XMarkIcon class="sm:hidden h-8 cursor-pointer" @click="minimizeChat"/>
        </div>
        <div class="flex flex-col justify-between p-5 flex-1">
          <div class="flex flex-col justify-center text-center">
            <input
                type="email"
                v-model="email"
                @keyup="validateEmail"
                @keyup.enter="login"
                :placeholder="$t('enterEmail')"
                :class="{
                'border-red-500': emailError,
                'border-gray-300': !emailError,
                'focus:outline-none': true,
                'focus:ring-2': true,
                'focus:ring-red-500': emailError,
              }"
                :style="{
                'focus-within:ring-color': emailError ? '#EF4444' : props.textColorPrimary,
              }"
                class="w-full px-4 py-2 border rounded-md transition duration-50 ease-in-out"
                @focus="isFocused = true"
                @blur="isFocused = false"
            />
            <span v-if="emailError" class="text-red-500 text-sm m-2 font-bold transition duration-50 ease-in-out">{{ emailError }}</span>
          </div>
          <button
              @click="login"
              :style="{ backgroundColor: props.backgroundColorPrimary, color: props.textColorPrimary }"
              class="px-4 py-2 rounded-md text-base font-medium shadow-md w-full transition duration-300 ease-in-out"
          >
            {{ $t('register') }}
          </button>
        </div>
      </div>
      <div v-else class="flex flex-col h-full">
        <div
            class="flex items-center p-2 border-b border-gray-300 justify-between sticky top-0 left-0 right-0 z-10 bg-white">
          <div v-if="isAgentAssigned" class="flex">
            <img :src="agentPhoto" alt="Agent photo" class="w-12 h-12 object-cover rounded-full mr-2"/>
            <div class="flex flex-col items-start">
              <h3 class="text-xl font-bold">{{ agentName }}</h3>
              <p class="text-gray-500">{{ $t('moderator') }}</p>
            </div>
          </div>
          <div v-else class="flex">
            <h3 class="text-xl font-bold">{{ $t('waitingMessage') }}</h3>
            <p v-if="isAgentAssigned" class="text-gray-500">{{ $t('moderator') }}</p>
          </div>
          <XMarkIcon class="sm:hidden h-8 cursor-pointer" @click="minimizeChat"/>
        </div>
        <div class="flex-1 overflow-y-auto flex flex-col p-2" ref="messagesContainer">
          <MessageBox v-for="message in messages" :key="message.id" :message="message"
                      :backgroundColorPrimary="props.backgroundColorPrimary"
                      :backgroundColorSecondary="props.backgroundColorSecondary"
                      :textColorPrimary="props.textColorPrimary"/>
          <div v-if="isTyping && isAgentAssigned" class="flex items-center mt-2">
            <div class="bg-gray-200 rounded-full px-5 py-2 mb-3">
              <span class="dot"></span>
              <span class="dot"></span>
              <span class="dot"></span>
            </div>
          </div>
        </div>
        <div v-if="isChatEnded" class="flex items-center px-4 py-4 border-t border-gray-300 bg-white justify-between">
          <p class="text-lg font-semibold text-red-500 text-center">{{ $t('chatEndedMessage') }}</p>
          <b class="text-lg cursor-pointer text-center" @click="resetChat">{{ $t('startNewChat') }}</b>
        </div>
        <footer v-else class="w-full sticky bottom-0 left-0 right-0 p-2 border-t border-gray-300 bg-white">
          <div class="flex items-center justify-between">
            <textarea v-model="newMessage" @keydown="handleKeyDown" :placeholder="$t('typeMessage')"
                      class="flex-1 p-2.5 border border-gray-300 rounded-md box-border text-base px-4 py-2 mr-2 resize-none"
                      :disabled="isChatEnded"/>
            <button @click="sendMessage" class="cursor-pointer" :disabled="isChatEnded">
              <PaperAirplaneIcon class="w-6 h-6"/>
            </button>
          </div>
        </footer>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import {ref, nextTick, watch} from 'vue';
import api, {getCSRFToken} from '@/api/axios';
import MessageBox from '@/components/MessageBox.vue';
import {PaperAirplaneIcon, XMarkIcon} from '@heroicons/vue/24/outline';
import {defineProps} from 'vue';

const props = defineProps({
  backgroundColorPrimary: String,
  backgroundColorSecondary: String,
  textColorPrimary: String,
  companyUuid: String,
});

const isAgentAssigned = ref(false);
const agentName = ref("");
const agentPhoto = ref();
const isLoggedIn = ref(false);
const email = ref('');
const emailError = ref('');
const newMessage = ref('');
const isFocused = ref(false);

//const profilePhoto = require('@/assets/logo.png');

const messagesContainer = ref(null);

const chatUuid = ref(null);
const messages = ref([]);
const isTyping = ref(false);
const isFriendTypingTimer = ref(false);
const isSending = ref(false);
const isChatEnded = ref(false);

const login = async () => {
  if (email.value) {
    if (!verifyEmail(email.value)) {
      return;
    }
    isLoggedIn.value = true;
    api.defaults.headers.common['X-CSRF-TOKEN'] = await getCSRFToken()
        .catch(() => {
          isLoggedIn.value = false;
          console.error('Error logging in chat');
        });
    // await api.get(`http://localhost/api/newchat/${email.value}`).then((response) => {

    await api.post("https://" + process.env.VUE_APP_WS_HOST + "/api/newchat", {
          'email': email.value,
          'company-uuid': props.companyUuid,
        },
    ).then((response) => {
      chatUuid.value = response.data

      window.Echo.channel(`chat.${chatUuid.value}.${props.companyUuid}`)
          .listen('MessageSent', (response) => {
            messages.value.push(response.message)
            nextTick(() => {
              scrollToBottom();
            });
          })
          .listen('AgentAssigned', async (response) => {
            agentName.value = response.agent_name;
            agentPhoto.value ="https://" + process.env.VUE_APP_WS_HOST + `/api/chat/${chatUuid.value}/agent`;
            isAgentAssigned.value = true;
          })
          .listen('UserTyping', async () => {
            isTyping.value = true;

            if (isFriendTypingTimer.value) {
              clearTimeout(isFriendTypingTimer.value);
            }

            isFriendTypingTimer.value = setTimeout(() => {
              isTyping.value = false;
            }, 1000)
          })
          .listen('EndedChat', async () => {
            isChatEnded.value = true;
          });
    }).catch(() => {
      console.error('Error logging in chat');
    });
  } else {
    emailError.value = 'Please enter your email address';
  }
};

const sendMessage = async () => {
  if (isSending.value === false && newMessage.value.trim() !== '') {
    isSending.value = true;
    api.defaults.headers.common['X-CSRF-TOKEN'] = await getCSRFToken()
        .catch(() => console.error('Error sending message'));
    api.post("https://" + process.env.VUE_APP_WS_HOST + `/api/chat/${chatUuid.value}/message`,  {
          'body': newMessage.value,
        }
    ).then(() => {
      newMessage.value = '';
      isTyping.value = false;
      clearTimeout(isFriendTypingTimer.value);
      isSending.value = false;

      nextTick(() => {
        scrollToBottom();
      });
    }).catch(() => {
      console.error('Error sending message');
      isSending.value = false;
    })
  }
};

const minimizeChat = () => {
  dispatchEvent(new CustomEvent('minimize-chat'));
};

const scrollToBottom = () => {
  requestAnimationFrame(() => {
    if (messagesContainer.value) {
      messagesContainer.value.scrollTo({
        top: messagesContainer.value.scrollHeight,
        behavior: 'smooth'
      });
    }
  });
};

const handleKeyDown = (event) => {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault();
    sendMessage();
  }
};

const resetChat = () => {
  if (isLoggedIn.value && isChatEnded.value) {
    isLoggedIn.value = false;
    isAgentAssigned.value = false;
    agentName.value = "";
    agentPhoto.value = "";
    chatUuid.value = null;
    messages.value = [];
    isTyping.value = false;
    isChatEnded.value = false;
  }
};

const verifyEmail = (email) => {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return re.test(email);
};

const validateEmail = () => {
  if (!verifyEmail(email.value)) {
    emailError.value = 'Invalid email address';
  } else {
    emailError.value = '';
  }
};

// Watch for changes in isTyping
watch(isTyping, (newVal) => {
  if (newVal && messagesContainer.value) {
    const isAtBottom = messagesContainer.value.scrollHeight - messagesContainer.value.scrollTop === messagesContainer.value.clientHeight;
    if (isAtBottom) {
      scrollToBottom();
    }
  }
});


</script>

<style scoped>
@keyframes blink {
  0%, 20%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

.dot {
  @apply inline-block w-2 h-2 mx-0.5 bg-gray-600 rounded-full;
  animation: blink 1.4s infinite both;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

.slide-fade-enter-active {
  transition: all 0.25s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(5%);
  opacity: 0;
}
</style>