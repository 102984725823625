import { createApp, defineCustomElement } from 'vue';
import vueCustomElement from 'vue-custom-element';
import 'document-register-element/build/document-register-element';
import MyWidget from './components/MyWidget.vue';
import App from './App.vue';
import './tailwind.css';
import { createI18n } from "vue-i18n";
import en from './i18n/en';
import de from './i18n/de';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: process.env.VUE_APP_BROADCASTER,
    key: process.env.VUE_APP_KEY,
    wsHost: process.env.VUE_APP_WS_HOST,
    wsPort: process.env.VUE_APP_WS_PORT ?? 80,
    wssPort: process.env.VUE_APP_WSS_PORT ?? 443,
    forceTLS: (process.env.VUE_APP_FORCE_TLS ?? 'false') === 'true',
    authEndpoint: process.env.VUE_APP_AUTH_ENDPOINT,
    enableTransports: (process.env.VUE_APP_ENABLE_TRANSPORTS ?? 'ws,wss').split(','),
});

const MyWidgetElement = defineCustomElement({
    ...MyWidget,
    // props: {
    //     backgroundColorPrimary: { type: String, default: "#88ff00" },
    //     backgroundColorSecondary: { type: String, default: "#888888" },
    //     textColorPrimary: { type: String, default: "#ffffff" },
    //     language: { type: String, default: "en" },
    //     companyUuid: { type: String }
    // },
});
customElements.define('my-widget', MyWidgetElement);

const app = createApp(App);

const i18n = createI18n({
    locale: document.documentElement.lang || 'en',
    fallbackLocale: 'en',
    legacy: false,
    messages: {
        en,
        de,
    },
});

app.use(vueCustomElement).use(i18n).mount('#widget--container');
