import axios from 'axios';
import {ref} from "vue";

const instance = axios.create({
    baseURL: `https://${process.env.VUE_APP_WS_HOST}`,
    withCredentials: true, // Important for cookies/session
});

let token = ref(null);
// Function to get CSRF token
export const getCSRFToken = async () => {
    const response = await instance.get('api/csrf-token');
    token.value = await response.data.token;
    return token.value;
};

export default instance;