<template>
  <div :class="['max-w-60 rounded-lg inline-block relative break-words', messageType]" :style="backgroundMessageType">
    <div class="text-sm relative">
      <span v-html="formattedMessage"></span>
      <div :style="{ color: props.textColorPrimary }" class="block mt-1 text-xs text-right">{{ formatDate(message.date) }}</div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import DOMPurify from 'dompurify';
import dayjs from 'dayjs';

const props = defineProps({
  message: {
    type: Object,
    required: true
  },
  backgroundColorPrimary: String,
  backgroundColorSecondary: String,
  textColorPrimary: String,
  companyUuid: String,
});

const messageType = computed(() => {
  return props.message.isAgent === true
      ? 'rounded-lg p-4 mb-2 break-words text-left self-start'
      : 'rounded-lg p-4 mb-2 break-words text-right self-end border border-gray-300 ';
});


const backgroundMessageType = computed(() => {
  return props.message.isAgent === true
      ? `background-color: ${props.backgroundColorSecondary}; color: ${props.textColorPrimary}`
      : `background-color: ${props.backgroundColorPrimary}; color: ${props.textColorPrimary}`
});

const formattedMessage = computed(() => {
  const rawMessage = props.message.body.replace(/\n/g, '<br>');
  return DOMPurify.sanitize(rawMessage);
});

const formatDate = (date) => {
  return dayjs(date).format('h:mm A');
};


</script>
